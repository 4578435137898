<template>
  <PageWithLayout
    class="page_detail"
    :isBodyFull="true"
    :leftRightPadding="20"
    :bottomPadding="104"
    :isCloseBtn="true"
    :isBackWebEnd="$route.query.isBackWebEnd && $route.query.isBackWebEnd === 'true'"
    :closeRouteName="viewModel.closeRouteName"
    :closeRouteQuery="$route.query"
    :closePage="$route.query.closePage">
    <div
      class="thumb_item"
      :style="{
        'background-image':`url(${viewModel.detailData.profile_image_url})`,
        'background-color': viewModel.detailData.profile_image_url ? 'none' : '#F3F1F1'
      }"></div>
    <strong class="tit_trans">{{ viewModel.detailData.transaction_name }}</strong>
    <span class="price_trans">{{ viewModel.detailData.transaction_amount | convertNumberToComma(false,'0') }}<span class="txt_price_unit">원</span></span>
    <div class="list_detail_info">
      <dl
        v-for="(infoData, index) in viewModel.detailData.display"
        :key="`infoItem${index}`">
        <dt :class="`tc_${infoData.key.color_name.toLowerCase()}`">{{ infoData.key.text }}</dt>
        <dd :class="`tc_${infoData.value.color_name.toLowerCase()}`">{{ infoData.value.text }}</dd>
      </dl>
    </div>
    <template v-if="!viewModel.isHideMemo">
      <Textarea
        :placeholder="$poData.trans_details_memo_form_placeholder"
        :value.sync="viewModel.detailData.memo"
        @update:value="value => viewModel.onUpdateMemo(value)"/>
      <Button
        btnStyle="primary"
        :disabled="!viewModel.memoChanged"
        :text="$poData.trans_details_save_btn"
        @onClickBtn="viewModel.putTransDetailMemo()"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import Textarea from '@/components/common/textarea/Textarea';
import Button from '@/components/common/button/Button';

// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
// viewModel
import TransDetailViewModel from '@/views/transaction/viewModel/TransDetailViewModel'

export default {
  name: 'TransDetail',
  mixins:[ InterFaceMixin ],
  components:{
    PageWithLayout,
    Textarea,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id;
    const query = this.$route.query;
    this.viewModel.init(id,query);
  },
  data(){
    return{
      viewModel: new TransDetailViewModel(),
    }
  },
}
</script>

<style scoped>
.page_detail{text-align:center}
/* overflow:hidden;min-height:100%; */
.page_detail >>> .body_drop{position:relative;padding-bottom:104px}
.page_detail .thumb_item{width:48px;height:48px;margin:1px auto 0;border-radius:100%;background-size:48px}
.page_detail .tit_trans{display:block;margin-top:6px;font-weight:600;font-size:14px;line-height:22px;color:#666}
.page_detail .price_trans{display:block;margin-top:4px;font-weight:700;font-size:40px;line-height:48px;color:#111}
.page_detail .price_trans .txt_price_unit{display:inline-block;margin:8px 0 0 4px;font-size:28px;line-height:36px;vertical-align:top}
.page_detail .list_detail_info{margin-top:16px}
.page_detail .list_detail_info dl{display:block;overflow:hidden;padding:12px 0}
.page_detail .list_detail_info dt{float:left;padding:1px 16px 1px 0;font-weight:500;font-size:14px;line-height:22px;text-align:left;color:#999}
.page_detail .list_detail_info dd{overflow:hidden;font-weight:500;font-size:16px;line-height:24px;text-align:right}
.page_detail .textarea_comm{margin:16px 0}
.page_detail .btn_primary{position:absolute;bottom:32px;left:20px;width:calc(100% - 40px)}
</style>