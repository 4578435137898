import { GET_REQUEST, PUT_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@/store/index';
import router from '@/router';

export default class TransDetailViewModel {
  constructor() {
    this.userType = ''; // PARENTS || CHILD || PARENTS_CHILD
    this.detailUid = '';
    this.detailData = {};
    this.isHideMemo = false;
    this.currentMemo = '';
    this.memoChanged = false;
    this.childUid = '';

    this.closeRouteName = '';
  }
  init(id, query){
    const { userType, childUid, closeRouteName } = query
    this.detailUid = id;
    this.userType = userType;
    if(childUid){
      this.childUid = childUid;
    }
    if(closeRouteName){
      this.closeRouteName = closeRouteName;
    }
    this.getTransDetail();
  }
  onUpdateMemo(val){
    this.memoChanged = val != this.currentMemo
  }

  // onClickClose(){
  //   router.push(
  //     { 
  //       name: this.closeRouteName,
  //       query: this.transListRouterQuery
  //     }
  //   );
  // }

  getTransDetail(){
    let path = '';
    switch(this.userType){
      case 'PARENTS': {
        path = `${apiPath.TRANS_DETAIL_PARENTS.format(this.detailUid)}`;
        if(!this.closeRouteName){
          this.closeRouteName = 'ParentsTransList';
        }
        break;
      }
      case 'CHILD': {
        path = `${apiPath.TRANS_DETAIL_CHILD.format(this.detailUid)}`;
        if(!this.closeRouteName){
          this.closeRouteName = 'ChildTransList';
        }
        break;
      }
      case 'PARENTS_CHILD': {
        path = `${apiPath.TRANS_DETAIL_PARENTS_CHILD.format(this.childUid,this.detailUid)}`;
        if(!this.closeRouteName){
          this.closeRouteName = 'ParentsTransListChild';
        }
        this.isHideMemo = true;
        break;
      }
    }
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.detailData = resultData;
      this.memoChanged = false;
      this.currentMemo = resultData.memo;
    }, (failed) => {
    })
  }
  putTransDetailMemo(){
    const path = `${apiPath.TRANS_DETAIL_MEMO.format(this.detailUid)}`;
    const data = {
      "body": {
        "memo": this.detailData.memo
      },
      "encrypt_body": "string",
      "header": {
        "notice": {
          "button": {
            "button_count": 0,
            "negative_button": {
              "action": "string",
              "text": "string"
            },
            "positive_button": {
              "action": "string",
              "text": "string"
            }
          },
          "is_cancelable": true,
          "is_visibility": true,
          "message": "작은내용",
          "title": "타이틀",
          "type": "ALERT"
        },
        "request_at": "2021-12-27 01:00:00",
        "response_at": "2021-12-27 01:00:02",
        "result": {
          "code": "200",
          "description": "age 값은 0보다 커야 합니다.",
          "message": "시스템 오류입니다.",
          "request_method": "GET",
          "request_uri": "https://lemontree.ai/user"
        }
      },
      "pagination": {
        "current_elements": 20,
        "current_page": 0,
        "page": 0,
        "size": 20,
        "total_elements": 2000,
        "total_page": 10
      },
      "sort": {
        "order_by": "id",
        "sort_by": "ASC"
      }
    }
    PUT_REQUEST(path,data).then(
      (success) => {
        store.dispatch('commonToast/fetchToastStart', 
        {
          msg : '메모가 저장되었습니다.',
          type: 'success'
        });
        this.getSelfTransDetail();
      }, (failed) => {
      })
  }
}